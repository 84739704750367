import './sass/main.sass'

import env from './env'

import assetPaths from './assetPaths'

import { Elm } from './elm/Main.elm'
import registerServiceWorker from './registerServiceWorker'

import * as signUps from './signUps'
import * as monitoring from './monitoring'
import { loadStripe } from '@stripe/stripe-js'
import * as Sentry from '@sentry/browser'

const LOCAL_STORAGE_PAGE_KEY = 'point-of-sale-page-persistence-v0'
const LOCAL_STORAGE_PAGE_EXPIRATION_KEY = `${LOCAL_STORAGE_PAGE_KEY}-expires`
const stripePublishableKey = process.env.ELM_APP_STRIPE_PUBLISHABLE_KEY
const butternutBoxAppUrl = process.env.ELM_APP_RAILS_URI

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: env.sentryDsn,
    release: env.version
  })
}

registerServiceWorker()

function getPersistedPage () {
  try {
    const expirationTimestring = window.localStorage.getItem(LOCAL_STORAGE_PAGE_EXPIRATION_KEY)
    const expirationDate = new Date(Number(expirationTimestring))
    const now = new Date()

    // if you give anything other than a valid Unix timestamp (either a number or
    // a string, JS doesn't mind) // to the Date constructor, you'll get an
    // `Invalid Date {}` object, whose `getDate` method returns NaN
    const stale = isNaN(expirationDate.getDate()) || now > expirationDate
    if (stale) {
      window.localStorage.clear()
      return null
    }

    const persistedPage = window.localStorage.getItem(LOCAL_STORAGE_PAGE_KEY)
    return (persistedPage) ? JSON.parse(persistedPage) : null
  } catch (_) {
    window.localStorage.clear()
    return null
  }
}

function persistPage (page) {
  const key = LOCAL_STORAGE_PAGE_KEY
  const value = JSON.stringify(page)
  window.localStorage.setItem(key, value)

  const expirationDate = new Date()
  expirationDate.setHours(23, 59, 59, 999) // 23:59 tonight
  const expirationTimestring = JSON.stringify(Number(expirationDate))
  window.localStorage.setItem(
    LOCAL_STORAGE_PAGE_EXPIRATION_KEY,
    expirationTimestring
  )
}

function sendTodaysSales (port, store) {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const startOfDay = today.getTime()

  port.send({
    complete: store.complete.filter(sale => sale.firstAttemptedAt >= startOfDay),
    pending: store.pending,
    failed: store.failed.filter(sale => sale.firstAttemptedAt >= startOfDay)
  })
}

document.addEventListener('DOMContentLoaded', () => {
  const persistedPage = getPersistedPage()
  const app = Elm.Main.init({
    node: document.getElementById('root'),
    flags: { assetPaths, env, persistedPage }
  })

  window.addEventListener('offline', () => {
    app.ports.updateOnlineStatus.send(false)
  }, false)

  window.addEventListener('online', () => {
    app.ports.updateOnlineStatus.send(true)
  }, false)

  app.ports.updateOnlineStatus.send(navigator.onLine)


  signUps.getAll().then(store => {
    sendTodaysSales(app.ports.updateStore, store)
  })

  async function networkRequest (uri, options) {
    try {
      return await fetch(uri, options)
    } catch (e) {
      throw new signUps.NetworkError(`A NetworkError occurred while making a request to ${uri}`)
    }
  }

  async function createClientSecret(data) {
    const bodyForClientSecret = {
      email: data.formData.email,
      first_name: data.formData.firstName,
      last_name: data.formData.lastName,
      dogs: data.dogs,
      discount_code_id: data.discount_code_id
    }
    const response = await networkRequest(`${butternutBoxAppUrl}/point-of-sale/v1/strong_customer_authentication/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${signUps.getAuthToken()}`
      },
      body: JSON.stringify(bodyForClientSecret)
    })
    const secret = await response.json()
    if (response.ok && secret.card_authentication_setup) {
      return secret.card_authentication_setup.client_secret
    } else {
      const message = `An unrecoverable error occurred making a request to Stripe. Status code: ${response.status}`
      throw new signUps.NetworkError(message)
    }
  }

  async function getPaymentMethodId(clientSecret, stripeToken) {
    const stripe = await loadStripe(stripePublishableKey)
    const response = await stripe.confirmCardSetup(
      clientSecret,
      {
        payment_method: { card: { token: stripeToken }}
      }
    )
    const intent = await response
     if (response && response.setupIntent) {
      return response.setupIntent.payment_method
    } else {
      return ''
    }
  }

  async function createStripeToken (formData) {
    const authHeader = `Bearer ${stripePublishableKey}`
    const headers = {
      'Accept': 'application/json',
      'Authorization': authHeader,
      'Content-Type': 'application/x-www-form-urlencoded'
    }
    const stripeParams = {
      'card[number]': formData.cardNumber,
      'card[exp_month]': formData.cardExpMonth,
      'card[exp_year]': formData.cardExpYear,
      'card[cvc]': formData.cardCVC
    }

    const body = Object.keys(stripeParams).map(key => {
      const encodedKey = encodeURIComponent(key)
      const encodedValue = encodeURIComponent(stripeParams[key])
      return `${encodedKey}=${encodedValue}`
    }).join('&')

    const response = await networkRequest('https://api.stripe.com/v1/tokens', {
      method: 'POST',
      headers,
      body
    })

    const token = await response.json()
    if (response.ok && token) {
      return token.id
    } else {
      const message = `An unrecoverable error occurred making a request to Stripe. Status code: ${response.status}`
      throw new signUps.NetworkError(message)
    }
  }

  app.ports.createPaymentMethod.subscribe( async data => {
    if (!navigator.onLine) { return app.ports.sendPaymentMethod.send('') }
    const stripeToken = await createStripeToken(data.formData)
    const clientSecret = await createClientSecret(data)
    const paymentMethodId = await getPaymentMethodId(clientSecret, stripeToken)
    app.ports.sendPaymentMethod.send(paymentMethodId)
  })

  app.ports.persistCustomer.subscribe(async customer => {
    const firstAttemptedAt = (new Date()).getTime()
    const completedAt = firstAttemptedAt
    const toSave = {
      ...customer,
      firstAttemptedAt,
      completedAt
    }
    const store = await signUps.persistSuccessfulSignUp(toSave)
    sendTodaysSales(app.ports.updateStore, store)
  })

  app.ports.attemptLater.subscribe(async customerDetails => {
    const firstAttemptedAt = (new Date()).getTime()
    const toTryLater = {
      ...customerDetails,
      firstAttemptedAt
    }
    const store = await signUps.attemptLater(toTryLater)
    sendTodaysSales(app.ports.updateStore, store)
  })

  app.ports.persistToLocalStorage.subscribe(persistPage)

  app.ports.offlineAuthToken.subscribe(signUps.setAuthToken)

  app.ports.monitoringEvent.subscribe(monitoring.send)

  window.setInterval(async () => {
    await signUps.retryAll()
    const store = await signUps.getAll()
    sendTodaysSales(app.ports.updateStore, store)
  }, 60 * 1000)
})
